<template>
  <div>
    <b-row>
      <b-col>
        <b-form
          class="mt-2"
          @submit.prevent
          @submit="editRental(rentalData, rentalFile)"
        >
          <b-tabs
            horizontal
            pills
            content-class="col-12 mt-1 mt-md-0"
            nav-wrapper-class="col-12"
            nav-class="nav-left"
          >
            <b-tab
              active
              @click="changeSelectedStep(1)"
            >
              <template #title>
                <feather-icon
                  icon="UsersIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Étape 1</span>
              </template>
              <b-card
                v-if="selectedStep === 1"
              >
                <b-row>
                  <b-col cols="12">
                    <h2 class="ml-2 mb-2">
                      Étape 1
                    </h2>
                  </b-col>
                  <b-col
                    sm="6"
                  >
                    <b-form-group
                      label="Device"
                      label-for="h-device"
                      label-cols-md="2"
                    >
                      <b-form-select
                        v-model="rentalData.deviceId"
                        name="h-device"
                        :options="deviceOptions"
                        text-field="name"
                        value-field="id"
                        required
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="6"
                  >
                    <b-form-group
                      label="Personne en charge"
                      label-for="h-person"
                      label-cols-md="2"
                    >
                      <v-select
                        v-model="rentalData.personInCharge"
                        :options="personOptions.filter(onlyUnique).sort()"
                        taggable
                        push-tags
                        label="Personne en charge"
                      >
                        <template #search="{attributes, events}">
                          <!-- eslint-disable-next-line -->
                          <input
                            class="vs__search"
                            :required="!rentalData.personInCharge"
                            v-bind="attributes"
                            v-on="events"
                          />
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="6"
                  >
                    <b-form-group
                      label="Début"
                      label-for="h-startDate"
                      label-cols-md="2"
                    >
                      <b-form-datepicker
                        id="h-startDate"
                        v-model="rentalData.startDate"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        placeholder="Début"
                        hide-header
                      />
                    </b-form-group>
                    <b-form-group
                      label="Fin"
                      label-for="h-endDate"
                      label-cols-md="2"
                    >
                      <b-form-datepicker
                        id="h-endDate"
                        v-model="rentalData.endDate"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        placeholder="Fin"
                        hide-header
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Utilisateur"
                      label-for="h-user"
                      label-cols-md="2"
                    >
                      <b-form-select
                        v-model="rentalData.user_id"
                        name="h-user"
                        :options="userOptions"
                        text-field="username"
                        value-field="id"
                      />
                    </b-form-group>
                    <b-form-group
                      label="Adresse"
                      label-for="h-address"
                      label-cols-md="2"
                    >
                      <b-form-input
                        v-model="rentalData.client_address"
                        name="h-address"
                      />
                    </b-form-group>
                    <b-form-group
                      label="Code postal"
                      label-for="h-postcode"
                      label-cols-md="2"
                    >
                      <b-form-input
                        v-model="rentalData.client_postcode"
                        name="h-postcode"
                      />
                    </b-form-group>
                    <b-form-group
                      label="Ville"
                      label-for="h-city"
                      label-cols-md="2"
                    >
                      <b-form-input
                        v-model="rentalData.client_city"
                        name="h-city"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Commentaire"
                      label-for="h-comment"
                    >
                      <b-form-textarea
                        v-model="rentalData.comment"
                        name="h-comment"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    cols="12"
                  >
                    <label>Fichier à joindre (Autorise PDF, word, excel, images)</label>
                    <b-form-file
                      v-model="filesUploadTemp"
                      accept=".jpg, .png, .pdf, .xls, .xlsx, .doc, .docx"
                      placeholder="Choisir un fichier..."
                      drop-placeholder="Déplacer un fichier ici..."
                      multiple
                      @input="uploadFileRental(filesUploadTemp, rentalData.id)"
                    />
                    <h4
                      v-if="filesRental && filesRental.length > 0"
                      class="mt-1"
                    >
                      Pièces jointes diverses
                    </h4>
                    <div
                      v-for="file, indexFile in filesRental"
                      :key="indexFile"
                    >
                      <b-link
                        class="data-orange"
                        @click="createAndDownloadBlobFile(file.content, file.originalname, file.extension)"
                      >
                        {{ file.originalname }}
                      </b-link>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
            <b-tab
              type="submit"
              @click="changeSelectedStep(2)"
            >
              <template #title>
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Étape 2</span>
              </template>
              <b-card
                v-if="selectedStep === 2"
              >
                <b-row>
                  <b-col cols="12">
                    <h2 class="ml-2 mb-2">
                      Étape 2
                    </h2>
                  </b-col>
                  <b-col
                    sm="6"
                  >
                    <b-form-group
                      label="Prix"
                      label-for="h-price"
                      label-cols-md="4"
                    >
                      <b-form-input
                        v-model="rentalData.price"
                        name="h-price"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="6"
                  >
                    <b-form-group
                      label-for="h-assurance"
                      label-cols-md="2"
                    >
                      <b-form-checkbox
                        v-model="rentalData.assurance"
                        checked="false"
                        name="h-assurance"
                        switch
                        inline
                      >
                        Assurance
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                      label-for="h-assistance"
                      label-cols-md="2"
                    >
                      <b-form-checkbox
                        v-model="rentalData.assistance"
                        checked="false"
                        name="h-assistance"
                        switch
                        inline
                      >
                        Assistance
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    sm="6"
                  >
                    <b-form-group
                      label="Compteur de chute"
                      label-for="h-fallCounter"
                      label-cols-md="4"
                    >
                      <b-form-input
                        v-model="rentalData.fallCounter"
                        name="h-fallCounter"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    sm="6"
                  >
                    <b-form-group
                      label="État de la tête"
                      label-for="h-headState"
                      label-cols-md="4"
                    >
                      <b-form-textarea
                        v-model="rentalData.headState"
                        name="h-headState"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="6"
                  >
                    <b-form-group
                      label="État du largueur"
                      label-for="h-largState"
                      label-cols-md="4"
                    >
                      <b-form-textarea
                        v-model="rentalData.largState"
                        name="h-largState"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
            <b-tab
              type="submit"
              @click="changeSelectedStep(3)"
            >
              <template #title>
                <feather-icon
                  icon="TruckIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Étape 3</span>
              </template>
              <b-card
                v-if="selectedStep === 3"
              >
                <b-row>
                  <b-col cols="12">
                    <h2 class="ml-2 mb-2">
                      Étape 3
                    </h2>
                  </b-col>
                  <b-col
                    sm="6"
                  >
                    <b-form-group
                      label="Date d'envoi"
                      label-for="h-sendDate"
                      label-cols-md="3"
                    >
                      <b-form-datepicker
                        id="h-sendDate"
                        v-model="rentalData.sendDate"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        hide-header
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
            <b-tab
              type="submit"
              @click="changeSelectedStep(4)"
            >
              <template #title>
                <feather-icon
                  icon="CheckCircleIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Étape 4</span>
              </template>
              <b-card
                v-if="selectedStep === 4"
              >
                <b-row>
                  <b-col cols="12">
                    <h2 class="ml-2 mb-2">
                      Étape 4
                    </h2>
                  </b-col>
                  <b-col
                    sm="6"
                  >
                    <b-form-group
                      label="Date de retour"
                      label-for="h-backDate"
                      label-cols-md="3"
                    >
                      <b-form-datepicker
                        id="h-backDate"
                        v-model="rentalData.backDate"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        hide-header
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
          </b-tabs>
          <b-col
            cols="12"
            class="d-flex justify-content-end"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              class="mt-2 mr-1"
            >
              Sauvegarder
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-primary"
              class="mt-2"
              :to="{ name: 'locations-view', params: { id: $router.currentRoute.params.id} }"
            >
              Annuler
            </b-button>
          </b-col>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTabs, BTab, BRow, BCol, BButton, BForm,
  BCard, BFormGroup, BFormSelect, BLink, BFormDatepicker,
  BFormInput, BFormTextarea, BFormFile, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
/* eslint no-underscore-dangle: 0 */
/* eslint no-param-reassign: 0 */

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BButton,
    BForm,
    BCard,
    BFormGroup,
    BFormSelect,
    BLink,
    BFormDatepicker,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BFormCheckbox,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      rentalData: {},
      value: 0,
      max: 5,
      selectedStep: 1,
      filesUploadTemp: [],
      filesRental: [],
      rentalFile: null,
      devicesList: store.state.device.devicesList.sort((a, b) => a.name.localeCompare(b.name)),
      userOptions: null,
      personOptions: ['aembarki', 'smarvie'],
    }
  },
  computed: {
    deviceOptions() {
      return this.devicesList.filter(device => device.rent)
    },
  },
  mounted() {
    store.dispatch('rental/findRental', this.$router.currentRoute.params.id)
      .then(rental => {
        this.rentalData = rental
        // rentals files
        store.dispatch('file/fetchFile', { rentalId: this.rentalData.id })
          .then(files => {
            if (files.length > 0) {
              files.forEach(file => {
                const projectImage = Buffer.from(file.content).toString('base64')
                file.content = projectImage
                this.filesRental.push(file)
              })
            }
          })
      })
    // hydrate userOptions
    if (this.$store.state.user.usersList) {
      this.userOptions = this.$store.state.user.usersList.sort((a, b) => a.username.localeCompare(b.username))
      this.userOptions.forEach(user => {
        user.fullname = `${user.firstname} ${user.lastname}`
      })
    }
  },
  methods: {
    async editRental(rentalData, rentalFile) {
      if (rentalFile !== null) {
        await store.dispatch('file/addFile', rentalFile)
      }
      await store.dispatch('rental/updateRental', { rentalId: rentalData.id, body: rentalData })
        .then(() => this.$router.push({ name: 'locations-view', params: { id: rentalData.id } }))
    },
    async uploadFileRental(filesUpload, rentalId) {
      const data = new FormData()
      filesUpload.forEach(file => {
        this.filesRental.push(file)
      })
      await filesUpload.forEach(file => {
        data.append('file', file)
        data.append('title', file.name)
        data.append('name', file.name)
        data.append('extension', file.type)
        data.append('mimetype', file.type)
        data.append('original_name', file.name)
        data.append('rentalId', rentalId)
      })
      this.rentalFile = data
    },
    changeSelectedStep(step) {
      this.selectedStep = step
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
